/* src/styles/GrammarChecker.css */

.grammar-checker-container {
  max-width: 800px;
  width: 100%;
  margin: 40px auto; /* Đặt ở giữa trang và không cố định */
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 80px); /* Giới hạn chiều cao để có cuộn dọc nếu cần */
  background: linear-gradient(to left, #fffff6, #ffe6e6);

}

.grammar-checker-container h2 {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: #ff4d4d;
  margin-bottom: 20px;
  background: linear-gradient(to right, #ff4d4d, #ff7f7f); /* Hiệu ứng gradient */
  -webkit-background-clip: text;
  color: transparent; /* Chỉ hiển thị màu nền */
  padding: 10px 0;
  position: relative;
}

.grammar-checker-container h2::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -1px;
  width: 50%;
  height: 3px;
  background-color: #ff4d4d;
  border-radius: 2px;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  resize: none;
  box-sizing: border-box;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

textarea:focus {
  border-color: #ff4d4d;
  outline: none;
}

button {
  background-color: #ff4d4d;
  color: #ffffff;
  padding: 12px 30px;
  border: none;
  border-radius: 30px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(255, 77, 77, 0.3);
}

button:hover {
  background-color: #ff1a1a;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(255, 26, 26, 0.4);
}

button:focus {
  outline: none;
}

.corrected-text {
  margin-top: 20px;
  padding: 15px;
  background-color: #e9f7ef;
  border: 1px solid #b2d8b2;
  border-radius: 8px;
  font-size: 16px;
  color: #333333;
  line-height: 1.5;
}

.loading {
  color: #ff4d4d;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.model-select {
  padding: 10px 15px;
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  width: 100%;
  max-width: 180px;
  cursor: pointer;
  transition: all 0.3s ease;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='%23999' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  margin-bottom: 20px;
}

.model-select:hover {
  border-color: #aaa;
}

.model-select:focus {
  border-color: #ff4d4d;
  box-shadow: 0 0 5px rgba(255, 77, 77, 0.3);
  outline: none;
}

.content {
  overflow-y: auto;
  padding: 40px;
}