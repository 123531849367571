/* Sidebar container */
.sidebar {
  width: 240px;
  padding: 80px 10px;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.1); /* Thêm bóng mờ nhẹ phía bên phải */
  z-index: 1;
  transition: all 0.3s ease;
  background: linear-gradient(to right, #fffff6, #ffe6e6); /* Gradient nhẹ sang màu nhạt hơn */
}

.content {
  margin-left: 260px;
  padding: 40px 60px;
  width: calc(100% - 260px);
}

/* Section Title */
.section-title {
  font-size: 1em;
  font-weight: bold;
  color: #ff4d4d;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0px 2px 8px rgba(255, 77, 77, 0.15);
  margin-bottom: 5px; /* Thêm khoảng cách giữa các mục */

}

.section-title:hover {
  background-color: #ffe6e6;
  color: #ff1a1a;
}

/* Arrow icon with rotation */
.arrow-icon {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.section-title:hover .arrow-icon {
  transform: rotate(90deg); /* Mũi tên quay ngang khi hover */
}

/* Sidebar items */
.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
}

.sidebar li {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 0.95em;
  color: #333;
  border-radius: 6px;
  transition: all 0.3s ease;
  background: #fafafa;
  margin-bottom: 5px; /* Thêm khoảng cách giữa các mục */

}

.sidebar li.selected {
  background-color: #ff4d4d;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 12px rgba(255, 77, 77, 0.3);
}

.sidebar li:hover {
  background-color: #ffe6e6;
  color: #ff1a1a;
}

/* Sub-menu with indentation */
.sub-menu {
  padding-left: 15px;
  margin-top: 8px;
}

/* Icon styles */
.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #ffb84d;
  transition: color 0.3s ease;
}

.sidebar li:hover .icon {
  color: #ff7f50;
}