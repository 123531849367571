body {
    margin-top: 60px;
}

.review-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
    width: 90%; /* Tăng chiều rộng để chiếm 90% màn hình */
    max-width: 1000px; /* Tăng giới hạn chiều rộng tối đa */
    max-height: 800px; /* Thêm chiều cao tối đa */
    overflow-y: auto; /* Cho phép cuộn dọc khi nội dung quá lớn */
    overflow-x: hidden; /* Ẩn cuộn ngang */
    margin: 0 auto;
    background: linear-gradient(to left, #fffff6, #ffe6e6);
    border-radius: 10px; /* Bo góc container */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Hiệu ứng bóng để nổi bật */
}
  
  .review-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  
  .review-card {
    width: 650px; /* Tăng chiều rộng của thẻ */
    height: 750px; /* Tăng chiều cao của thẻ */
    perspective: 1000px;
    cursor: pointer;
}

.review-card .card-front,
.review-card .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Hoặc center để căn giữa nội dung */
    align-items: center; /* Căn giữa theo chiều ngang */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.6s ease;
    padding: 30px; /* Tăng khoảng cách bên trong */
    overflow-y: auto; /* Cho phép cuộn nếu nội dung quá lớn */
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    text-align: justify; /* Đảm bảo chữ căn đều */
    color: #2c3e50;
    background: linear-gradient(to left, #fffff6, #ffe6e6);
    border: 1px solid #e0e7ff;
}

/* Tăng cường căn chỉnh để nội dung không bị lệch */
.review-card .card-front > *,
.review-card .card-back > * {
    margin: 0 auto; /* Đảm bảo các phần tử con căn giữa theo chiều ngang */
    width: 100%; /* Để nội dung không bị thu hẹp */
    text-align: justify; /* Hoặc center nếu muốn căn giữa */
}
  
.review-card .card-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Căn giữa theo chiều dọc */
    align-items: center; /* Căn giữa theo chiều ngang */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.6s ease;
    padding: 30px; /* Tăng khoảng cách bên trong */
    overflow-y: auto; /* Cho phép cuộn nếu nội dung quá lớn */
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    font-size: 24px; /* Tăng kích thước chữ */
    font-weight: bold; /* Tăng độ đậm */
    line-height: 1.8; /* Điều chỉnh khoảng cách dòng */
    border: 1px solid #ffe6e6; /* Viền màu nổi bật */
    background: linear-gradient(to right, #fffff6, #ffe6e6);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Hiệu ứng đổ bóng chữ */
}

.review-card .card-front p {
    font-size: 22px; /* Tăng kích thước chữ */
    text-align: center; /* Căn giữa nội dung chữ */
}

.review-card .card-front h3 {
    color: #1d4ed8; /* Màu chữ nổi bật hơn */
    font-size: 42px; /* Tăng kích thước chữ */
    text-align: center; /* Căn giữa nội dung chữ */
    margin-bottom: 20px; /* Tăng khoảng cách với phần tử dưới */
}

.review-card .card-back h4 {
  color: #1d4ed8; /* Màu chữ nổi bật hơn */
  text-align: center; /* Căn giữa nội dung chữ */
  margin-bottom: 20px; /* Tăng khoảng cách với phần tử dưới */
}

  .review-card.flipped .card-front {
    transform: rotateY(180deg);
  }
  
  .review-card.flipped .card-back {
    transform: rotateY(0deg);
  }
  
  .card-front {
    color: #333;
    transform: rotateY(0deg);
  }
  
  .card-back {
    background: linear-gradient(145deg, #f3f4f6, #e0e7ff);
    color: #333;
    transform: rotateY(180deg);
  }
  
  .btn-back,
  .btn-next {
    background-color: #4f46e5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .btn-back {
    margin-right: 20px;
  }
  
  .btn-next {
    margin-left: 20px;
  }
  
  .btn-back:hover,
  .btn-next:hover {
    background-color: #3b82f6;
  }