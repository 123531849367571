/* SearchWord Container */
.search-word-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to left, #fffff6, #ffe6e6);

}

.search-word-container h2 {
  text-align: center;
  font-size: 2rem;
  color: #ff4d4d;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Form Styles */
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

input:focus {
  border-color: #ff4d4d;
  outline: none;
}

/* Button Styles */
.button-search {
  display: block;
  width: 100%;
  background-color: #ff4d4d;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #ff1a1a;
  transform: translateY(-2px);
}

button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Error Message */
.error-message {
  color: #d9534f;
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
}

/* Result Section */
.result {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.result h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.result-item {
  margin-bottom: 15px;
  font-size: 1.1rem;
  line-height: 1.5;
}

.result-item strong {
  color: #ff4d4d;
  margin-right: 8px;
}

.result-item p {
  margin: 5px 0;
}

.result-item ul {
  margin: 10px 0 10px 20px;
}

.result-item li {
  list-style: disc;
}

/* Loading Spinner */
.loading {
  text-align: center;
  color: #ff4d4d;
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 20px;
}

/* Button dấu cộng */
.add-to-vocabulary {
    background-color: #ff4d4d; /* Nền đỏ */
    color: #ffffff !important; /* Màu trắng */
    font-size: 1.5em; /* Kích thước dấu '+' */
    padding: 5px 15px;
    border: none;
    border-radius: 50%; /* Bo tròn button */
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(255, 77, 77, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 1; /* Căn chỉnh dấu cộng */
  }
  
  .add-to-vocabulary::before {
    content: "+"; /* Đảm bảo dấu cộng được thêm */
    color: #ffffff !important; /* Màu trắng */
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .add-to-vocabulary:hover {
    background-color: #ff1a1a;
    box-shadow: 0 6px 12px rgba(255, 26, 26, 0.4);
    transform: scale(1.1); /* Hiệu ứng phóng to khi hover */
  }
  
  .add-to-vocabulary:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(255, 77, 77, 0.8);
  }
  .add-to-vocabulary:hover {
    background-color: #ff1a1a;
    box-shadow: 0 6px 12px rgba(255, 26, 26, 0.4);
    transform: scale(1.1); /* Hiệu ứng phóng to khi hover */
  }
  
  .add-to-vocabulary:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(255, 77, 77, 0.8);
  }

  
  
  /* Thông báo thành công */
  .success-message {
    color: #28a745; /* Màu xanh lá */
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
    background-color: #e6f9ec; /* Màu nền nhẹ */
    border: 1px solid #b2d8b2;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(40, 167, 69, 0.1);
    animation: fadeIn 0.8s ease-in-out;
  }
  
  /* Animation hiệu ứng mờ dần */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }