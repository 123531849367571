/* src/styles/Header.css */
.header {
  position: fixed; /* Đặt header cố định ở trên cùng */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Đảm bảo header luôn hiển thị trên cùng */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 20px;
  border-bottom: 3px solid #ff4d4d; /* Viền đỏ phía dưới */
  
}

.logo {
  display: flex;
  align-items: center;
}

.logo-icon {
  width: 52px;
  height: 52px;
  background-color: #ff4d4d; /* Màu nền đỏ */
  border-radius: 50%; /* Hình tròn */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.logo-icon span {
  font-size: 24px; /* Kích thước icon */
}

/* Logo text style */
.logo-text {
  font-size: 26px;
  font-weight: 700;
  color: #ff5e5e; /* Màu đỏ nhạt hơn một chút */
  background: linear-gradient(45deg, #ff5e5e, #ffa07a); /* Gradient màu tươi sáng hơn */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 
    0px 0px 2px rgba(255, 100, 100, 0.6), /* Bóng sáng vừa phải để rõ chữ */
    0px 0px 4px rgba(255, 150, 150, 0.4); /* Bóng mờ nhẹ để tạo hiệu ứng sáng */
  transition: transform 0.3s ease, text-shadow 0.3s ease;
}

.logo-text:hover {
  text-shadow: 
    0px 0px 4px rgba(255, 100, 100, 0.7), /* Tăng nhẹ sáng khi hover */
    0px 0px 6px rgba(255, 180, 180, 0.5); /* Hiệu ứng sáng nhẹ hơn khi hover */
  transform: scale(1.05);
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px; /* Tạo khoảng cách đều giữa các nút */
}

.content {
  padding-top: 0px; /* Căn chỉnh theo chiều cao của header */
}

.logo-image {
  width: 70px; /* Tùy chỉnh kích thước của ảnh */
  height: 70px;
}

.login-container {
  margin: 0;
  padding: 0;
}

.assistant-button {
  padding: 12px 25px; /* Kích thước nút */
  background: linear-gradient(90deg, #4d4dff, #1a1aff); /* Gradient màu xanh giống nút submit */
  color: #fff; /* Màu chữ */
  border: none; /* Xóa viền */
  border-radius: 25px; /* Bo góc */
  font-size: 1.1em; /* Kích thước chữ */
  cursor: pointer; /* Con trỏ dạng bàn tay */
  transition: all 0.3s ease; /* Hiệu ứng chuyển đổi */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Bóng đổ */
}

.assistant-button:hover {
  background: linear-gradient(90deg, #6a6aff, #3333ff); /* Thay đổi màu khi hover */
  transform: translateY(-2px); /* Nhấc nhẹ nút */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Bóng đổ khi hover */
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-container {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.popup-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.popup-cancel-btn,
.popup-logout-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.popup-cancel-btn {
  background-color: #ddd;
  color: #333;
  transition: background-color 0.3s ease;
}

.popup-cancel-btn:hover {
  background-color: #ccc;
}

.popup-logout-btn {
  background-color: #ff4d4d;
  color: #fff;
  transition: background-color 0.3s ease;
}

.popup-logout-btn:hover {
  background-color: #e33e3e;
}