/* src/styles/TranslateFile.css */

.translate-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 120px auto 0 auto;
    padding: 20px;
    background: linear-gradient(to left, #fffff6, #ffe6e6);
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    gap: 20px;
}

.upload-section {
    width: 90%;
    padding: 30px;
    border: 2px dashed #ff4d4d; /* Màu viền chủ đạo */
    border-radius: 10px;
    text-align: center;
    background-color: #ffe6e6; /* Màu nền nhạt từ màu chủ đạo */
    color: #ff4d4d; /* Màu chữ đồng bộ */
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.upload-section:hover {
    background-color: #ffd6d6; /* Màu nền hover tinh tế */
    border-color: #e64545; /* Màu viền hover đậm hơn */
    box-shadow: 0 4px 12px rgba(230, 69, 69, 0.2); /* Hiệu ứng bóng đổ nhẹ */
}

.upload-section p {
    font-size: 16px;
    color: #ff4d4d;
    font-weight: 500;
    margin: 0;
}

.upload-section::before {
    content: 'Drag and drop files here';
    color: #ff4d4d;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.upload-section .choose-files {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #ff4d4d;
    color: #fff;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.upload-section .choose-files:hover {
    background-color: #e64545;
    transform: scale(1.03);
}

/* Khi người dùng kéo tệp vào */
.upload-section.dragging {
    background-color: #ffd6d6;
    border-color: #e64545;
    box-shadow: 0 4px 12px rgba(230, 69, 69, 0.3);
}

.language-select-section {
    margin-top: 20px;
    color: #ff4d4d;
}

.translate-button-section button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.translate-button-section button:hover {
    background-color: #e64545;
    transform: scale(1.03);
}

.file-status-section {
    width: 100%;
}

.file-status-item {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Đảm bảo các phần tử cùng hàng và căn giữa */
    padding: 10px;
    border-bottom: 1px solid #ff4d4d;
    font-weight: 500;
    color: #333;
}

.file-info {
    display: flex;
    align-items: center;
    gap: 8px; /* Khoảng cách giữa tên file và icon */
}

.file-name {
    font-size: 14px;
    color: #333;
}

.file-status {
    display: flex;
    align-items: center;
    gap: 5px; /* Khoảng cách giữa các icon trạng thái */
}

.loading-spinner {
    font-size: 18px;
    color: #ff4d4d;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.success-icon {
    font-size: 18px;
    color: green;
}

.error-icon {
    font-size: 18px;
    color: red;
}