/* src/styles/IntroductionPage.css */

.introduction-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg,rgba(255, 77, 77, 0.3), #fffff6); /* Gradient nền sáng hơn */
  overflow: hidden;
}
.page-icon {
  width: 180px; /* Kích thước icon */
  margin-bottom: 10px; /* Khoảng cách giữa icon và nội dung */
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.intro-background {
  text-align: center;
  padding: 50px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  max-width: 650px;
}

.intro-content h1 {
  font-size: 2.8em;
  font-weight: 700;
  background: linear-gradient(90deg, #ff4d4d, #ff7f50);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 8px rgba(255, 77, 77, 0.2);
  margin-bottom: 15px;
}

.intro-content p {
  font-size: 1.15em;
  color: #666;
  margin-top: 10px;
  margin-bottom: 25px;
  line-height: 1.6;
}

.explore-button {
  padding: 12px 25px;
  margin: 10px;
  background: linear-gradient(90deg, #ff4d4d, #ff1a1a);
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 1.05em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.explore-button:hover {
  background: linear-gradient(90deg, #ff6a6a, #ff3333);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
}

.intro-content h1 {
  font-size: 2.8em;
  font-weight: 800;
  color: #333;
  margin-bottom: 10px;
}

.intro-content .highlight {
  color: #ff4d4d;
  font-weight: 700;
}

.intro-content .intro-text {
  font-size: 1.3em;
  color: #666;
  margin-top: 10px;
  line-height: 1.5;
}

.model-info h2 {
  font-size: 1.6em;
  margin-top: 20px;
  color: #333;
  font-weight: 600;
}

.model-info p {
  font-size: 1.1em;
  color: #555;
  margin-top: 8px;
  line-height: 1.6;
}

