/* Popup container */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Nội dung pop-up */
.popup-content {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 12px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

/* Nút đóng */
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

/* Logo và tiêu đề */
.login-header {
  margin-bottom: 20px;
}

.login-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.login-header h2 {
  font-size: 24px;
  margin: 10px 0;
  color: #2c3e50;
}

.login-header p {
  color: #7f8c8d;
  font-size: 14px;
}

/* Form đăng nhập */
.login-form label {
  display: block;
  text-align: left;
  color: #34495e;
  margin-top: 15px;
  font-weight: bold;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
}

.password-field {
  position: relative;
}

.show-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Nút quên mật khẩu */
.forgot-password {
  display: block;
  text-align: left;
  margin: 10px 0;
  color: #ff4d4d;
  font-size: 14px;
  text-decoration: none;
}

/* Nút đăng nhập */
.popup-login-button {
  background-color: #ff4d4d;
  color: #ffffff;
  margin-top: 10px;
  padding: 12px 30px;
  border: none;
  border-radius: 30px; /* Rounded edges for a modern look */
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(255, 77, 77, 0.3); /* Soft shadow */
}

/* Đăng ký */
.register {
  margin-top: 20px;
  color: #ff4d4d;
  font-size: 14px;
}

.register a {
  color: #ff4d4d;
  text-decoration: none;
}

.toggle-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.toggle-link:hover {
  color: #0056b3;
}

.error-popup {
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.9);
  color: white;
  padding: 20px 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  z-index: 1000; /* Hiển thị trên tất cả */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.error-popup p {
  margin: 0;
}