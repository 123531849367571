.assistant-container {
    display: flex;
    flex-direction: row; /* Hiển thị form và response song song */
    align-items: flex-start; /* Căn chỉnh về đầu container */
    justify-content: flex-start; /* Đẩy container sang bên phải */
    position: fixed;
    top: 70px; /* Trừ chiều cao của header */
    left: 260px; /* Đẩy container sang phải bằng chiều rộng Sidebar */
    width: calc(100% - 260px); /* Trừ chiều rộng của Sidebar */
    height: calc(100vh - 70px); /* Trừ chiều cao của header */
    background: linear-gradient(135deg, #f6f9fc, #e3effb); /* Nền gradient nhạt, thân thiện */
    padding: 20px;
    gap: 20px; /* Khoảng cách giữa form và response */
    overflow-y: auto; /* Cuộn nếu nội dung quá dài */
}

/* Assistant Form styling */
.assistant-form {
    text-align: center;
    padding: 40px;
    background: #ffffff; /* Nền trắng tinh khôi */
    border-radius: 16px; /* Bo góc mềm mại hơn */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Bóng đổ nhẹ */
    max-width: 600px;
    width: 48%; /* Chiếm 48% chiều rộng container */
    max-height: 920px;
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Hiệu ứng hover */
}

.assistant-form:hover {
    transform: translateY(-5px); /* Hiệu ứng nhấc nhẹ */
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15); /* Bóng đổ mạnh hơn */
}

.ai-greeting {
    font-size: 2.5rem; /* Kích thước chữ lớn */
    font-weight: 700; /* Đậm */
    background: linear-gradient(90deg, #4d4dff, #00d4ff); /* Gradient màu xanh AI */
    -webkit-background-clip: text; /* Cắt gradient vào phần chữ */
    -webkit-text-fill-color: transparent; /* Làm chữ trong suốt để thấy gradient */
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Bóng đổ nhẹ */
    margin-bottom: 20px; /* Khoảng cách dưới */
    text-align: center; /* Căn giữa dòng chữ */
    font-family: 'Roboto', sans-serif; /* Font hiện đại */
    animation: pulse 2s infinite; /* Hiệu ứng nhấp nháy nhẹ */
}

/* Hiệu ứng pulse nhẹ */
@keyframes pulse {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.05);
        opacity: 0.9;
    }
}

.form-group {
    margin-bottom: 20px; /* Khoảng cách giữa các trường */
}

.form-group label {
    display: block;
    margin-bottom: 8px; /* Khoảng cách giữa label và input/textarea */
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    text-align: left; /* Căn trái label */
}

.assistant-form input,
.assistant-form textarea,
#supportType {
    display: block; /* Đảm bảo các trường được hiển thị như block */
    width: 100%; /* Chiếm toàn bộ chiều ngang container */
    padding: 15px; /* Đồng bộ padding */
    margin: 15px 0; /* Khoảng cách giữa các trường input */
    font-size: 1.1em;
    border: 1px solid #ddd;
    border-radius: 12px;
    outline: none;
    transition: all 0.3s ease;
    background: #f9f9f9;
    box-sizing: border-box; /* Đảm bảo padding không làm tăng kích thước */
}

.assistant-form input:focus,
.assistant-form textarea:focus,
#supportType:focus {
    border-color: #4d4dff;
    box-shadow: 0 0 8px rgba(77, 77, 255, 0.3);
    background: #ffffff;
}

.assistant-form button {
    padding: 12px 30px;
    margin-top: 10px;
    background: linear-gradient(90deg, #4d4dff, #1a1aff);
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 1.1em;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.assistant-form button:hover {
    background: linear-gradient(90deg, #6a6aff, #3333ff);
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Assistant Response styling */
/* Assistant Response styling */
.assistant-response {
    flex-grow: 1;
    text-align: left;
    padding: 30px;
    background: #f5f7fa; /* Nền xám nhạt dễ đọc */
    border-radius: 16px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 820px;
    width: 48%; /* Chiếm 48% chiều rộng container */
    height: 859px; /* Chiều cao cố định */
    overflow-y: auto;
    border: 4px solid transparent; /* Viền ban đầu trong suốt */
    transition: border-color 0.3s ease;
}

/* Hiệu ứng khi loading */
.assistant-response.loading {
    animation: siri-border 2s linear infinite; /* Thêm animation viền */
}

/* Định nghĩa animation Siri-style */
@keyframes siri-border {
    0% {
        border-color: #4d4dff; /* Màu xanh dương */
    }
    25% {
        border-color: #1aff1a; /* Màu xanh lá */
    }
    50% {
        border-color: #ff4d4d; /* Màu đỏ */
    }
    75% {
        border-color: #ff9933; /* Màu cam */
    }
    100% {
        border-color: #4d4dff; /* Quay lại màu xanh dương */
    }
}
.assistant-response h3 {
    font-size: 1.8em;
    font-weight: 700;
    color: #333333; /* Màu đậm để dễ đọc hơn */
    margin-bottom: 20px;
}

.assistant-response p {
    font-size: 1.2em;
    color: #555555;
    line-height: 1.6;
    word-wrap: break-word; /* Đảm bảo văn bản dài không tràn */
}

.assistant-response p strong {
    color: #1a1aff; /* Làm nổi bật từ in đậm */
}

.assistant-response p em {
    color: #666666; /* Làm nổi bật từ in nghiêng */
}

/* Styles for the select box (combobox) */
#model-select, #supportType {
    padding: 15px; /* Đồng bộ padding với input/textarea */
    border: 1px solid #ddd;
    border-radius: 12px;
    font-size: 1.1em;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
}

/* Hover và focus cho combobox */
#model-select:hover, #supportType:hover {
    border-color: #4d4dff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

#model-select:focus, #supportType:focus {
    outline: none;
    border-color: #4d4dff;
    box-shadow: 0 0 8px rgba(0, 86, 179, 0.8);
}

/* Add some space between the dropdown and other elements */
.form-group select {
    margin-bottom: 15px;
}

/* Ensure compatibility with mobile devices */
@media (max-width: 768px) {
    .assistant-form input,
    .assistant-form textarea,
    #supportType {
        font-size: 1rem;
        padding: 12px;
    }

    .form-group {
        margin-bottom: 15px;
    }
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #4d4dff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loading-spinner p {
    margin-top: 10px;
    font-size: 1rem;
    color: #555;
}